.login {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;

  .img {
    grid-area: 1 / 1 / 2 / 2;
    height: calc(100vh - 60px);
  }

  .connexion {
    grid-area: 1 / 2 / 2 / 3;
  }

  @media (max-width: 1400px) and (min-height: 1200px) {
    align-items: center;
    align-content: center;

    .img {
      height: 60%;
    }
  }

  @media (max-width: 1200px) and (min-height: 700px) {
    align-items: center;
    align-content: center;
    .img {
      height: 50%;
    }
  }

  @media (max-width: 912px) , (max-height: 500px) {
    display: flex;
    align-items: start;
    overflow-x: auto;

    .img {
      display: none;
    }

    .connexion {
      width: 100%;
      height: 100%;
    }

    .backTertiary {
      background-color: transparent;
    }

    .flexVertical {
      text-align: center;
    }

  }

  @media (max-width: 300px), (max-height: 300px) {
    padding: 10px;
    overflow-x: auto;
  }
}

@media (max-width: 912px) , (max-height: 500px) {
  .pV30, pL30 {
    padding: 30px;
  }

  @media (min-height: 300px) {
    .pV30, pL30 {
      padding: 8px;
    }
  }
}
