
$color-white: white;

$color-gray-900: #262631;
$color-gray-800: #3C3C4E;
$color-gray-700: #52526B;
$color-gray-600: #686887;
$color-gray-500: #8383A0;
$color-gray-400: #A0A0B6;
$color-gray-300: #BDBDCC;
$color-gray-200: #DADAE2;
$color-gray-100: #F7F7F8;

$color-blue-900: #111E32;
$color-blue-800: #1D3557;
$color-blue-700: #224A87;
$color-blue-600: #3764A4;
$color-blue-500: #4B7DC3;
$color-blue-400: #7199D0;
$color-blue-300: #98B4DD;
$color-blue-200: #BED0E9;
$color-blue-100: #E4ECF6;

$color-green-900: #11402E;
$color-green-800: #1C694A;
$color-green-700: #279167;
$color-green-600: #26C485;
$color-green-500: #43DB9E;
$color-green-400: #6EE3B4;
$color-green-300: #98EBCA;
$color-green-200: #C3F3E0;
$color-green-100: #EEFCF6;

$color-yellow-900: #181402;
$color-yellow-800: #473D05;
$color-yellow-700: #776609;
$color-yellow-600: #A78F0C;
$color-yellow-500: #D6B80F;
$color-yellow-400: #F0D129;
$color-yellow-300: #F6E588;
$color-yellow-200: #FAF0B9;
$color-yellow-100: #FDFAE7;

$color-red-900: #8B2323;
$color-red-800: #BF2222;
$color-red-700: #DC3838;
$color-red-600: #F32020;
$color-red-500: #FF4747;
$color-red-400: #FF7A7A;
$color-red-300: #FFADAD;
$color-red-200: #FFE0E0;
$color-red-100: #FAE5E5;

