@import "../style/variablesColor";

.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;

  > div {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: $color-blue-800;
    border: 2px solid $color-blue-800;

  }

  .initial:hover {
    background-color: $color-gray-200;
  }

  .disable {
    color: $color-gray-400;
    border: 2px solid $color-gray-400;
    cursor: not-allowed;
  }

  .checked {
    background-color: $color-blue-800;
  }

  label {
    cursor: pointer;
  }

  label.disableLabel {
    cursor: not-allowed;
    color: $color-gray-400
  }

  @media (max-width: 850px) {
    > div {
      width: 14px;
      height: 14px;
    }
  }
}