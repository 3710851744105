.unavaible {
  background: url("../assets/img/background-404.png");

  .logo {
    left: 50px;
    top: 50px;
    width: 15%;
  }

  @media (max-width: 912px) {
    .vroomy404 {
      width: 70%;
    }

    .logo {
      left: 20px;
      top: 20px;
      width: 40%;
    }

    .fontSize32 {
      font-size: 18px;
    }

    .fontSize20 {
      font-size: 12px;
    }

    > div {
      margin: 0 30px;
    }
  }

  @media (max-height: 360px) {
    .vroomy404 {
      width: 50%;
    }

    .logo {
      left: 20px;
      top: 20px;
      width: 20%;
    }
  }
}

@media (max-height: 360px) {
  .gap32 {
    gap: 16px;
  }
}