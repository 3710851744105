.content {
  h2 {
    margin-bottom: 50px;
  }

  .situaction {
    transform: translateY(-50%);
    margin-top: -50px;
  }

  .geoloc {
    position: absolute;
    transform: translateY(-50%);
    margin-top: -10px;
    width: 10%;
  }

  .mT50 {
    margin-top: 50px;
  }

  form {
    width: 100%;
    display: flex;
  }

  .logotypeGeoloc {
    visibility: hidden;
    display: none;
  }

  @media (max-width: 1400px) {
    .flexHorizontalSpaceBetweenCenter {
      flex-direction: column;
      gap: 16px;
    }

  }

  @media (max-width: 912px) , (max-height: 500px) {
    width: 100%;
    height: 100%;
    border: none;

    .situaction {
      width: 100px;
      transform: translateY(0);
      margin-top: 0;
      position: initial;
    }

    .geoloc {
      visibility: hidden;
      display: none;
    }

    .logotypeGeoloc {
      visibility: initial;
      display: initial;
      width: 100px;
    }

    .footerLogin {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  @media (max-height: 800px) {
    height: 90%;
    text-align: center;
    gap: 46px;
    flex-wrap: nowrap;
    justify-content: center;

    h2 {
      margin-bottom: 0;
    }

    .situaction {
      width: 100px;
      transform: translateY(0);
      position: initial;
    }

    .geoloc {
      position: initial;
      width: 180px;
    }

    .fontSize16 {
      font-size: 14px;
    }

    .bottom35 {
      bottom: 35px;
    }

    .mT50 {
      margin-top: 0;
    }

    button {
      margin: 0 20px;
    }
  }
  @media (max-width: 730px), (max-height: 550px) {
    height: 100%;
    flex-wrap: nowrap;

    .absolute {
      position: initial;
    }

    .footerLogin {
      margin-bottom: 20px;
    }
  }


  @media (max-height: 512px) {
    height: 100%;
    gap: 32px;
    flex-wrap: nowrap;

    .situaction {
      display: none;
      width: 80px;
      transform: translateY(-0%);
    }

    .gap32 {
      gap: 8px;
    }

    .p50 {
      padding: 0;
    }

    .bottom35 {
      bottom: -20px;
    }

    .flexVertical {
      margin-bottom: 0;
    }

    .flexHorizontalSpaceBetweenCenter {
      flex-direction: row;
    }

    .absolute {
      position: initial;
    }

    .footerLogin {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 300px), (max-height: 300px) {
    gap: 24px;

    .bottom35 {
      bottom: 0;
    }
  }
}