@import "../../style/variables";
@import "../style/variablesColor";

.input-label-container {
  position: relative;
  padding: 10px 5px;
  
  input[type="password"] {
    font-family: Verdana;
    letter-spacing: 2px;
    font-weight: 900;
  }

  input {
    color: $color-blue-800;
  }

  &.success {
    border: 1px solid $color-green-600;

    .floating-input {
      &:focus{
        ~ .floating-label{
          color: $color-green-600 !important;
        }
      }
    }

    .floating-input:not(:placeholder-shown) ~ .floating-label {
      color: $color-green-600;
    }
  }

  &.error {
    border: 1px solid $color-red-500;
  }

  &.primary {
    border: 1px solid $color-blue-800;
    color: $color-blue-800;
  }

  .floating-label { 
    position:absolute;
    pointer-events:none;
    padding:5px 10px;
    font-size: 16px;
    top: 50%;
    transform: translateY(-50%);
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .floating-input {
    display:block;
    width:100%;
    font-size: 16px;
    box-sizing: border-box;
    &:focus{
      ~ .floating-label{
        top:0;
        font-size:14px;
        color: $primary-color;
      }
    }
  }
  
  .floating-input:not(:placeholder-shown) ~ .floating-label {
    top: 0;
    font-size:14px;
  }


  @media (max-width: 900px) ,(max-height: 500px) {
    label {
      background-color: white !important;
    }
  }
  
  .leftIcon {
    left: 30px;
  }
  .leftWithoutIcon {
    left: 10px;
  }

}

.inputError {
  .floating-input {
    &:focus{
      ~ .floating-label{
        color: $color-red-500 !important;
      }
    }
  }

  .floating-input:not(:placeholder-shown) ~ .floating-label {
    color: $color-red-500;
  }
}

.disabled {
  color: $color-gray-400;
  border: 1px solid $color-gray-400;
}

.iconDisabled {
  color: $color-gray-400;
}

.iconColor {
  color: $color-blue-800;
}


