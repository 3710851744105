@import "variables";

#root {
  margin: 0;
  font-family: $font-family-urbanist;
  width: 100%;
  height: 100%;
  font-size: $font-size-18;
  font-weight: 500;
}

input {
  background-color: transparent;
  border: none;
  outline: none;
}

/* Font-family */
.poppins{
  font-family: $font-family-poppins;
}

.urbanist{
  font-family: $font-family-urbanist;
}

.unbounded{
  font-family: $font-family-unbounded;
}

/* Font-size */
.fontSize8 {
  font-size: $font-size-8;
}

.fontSize10 {
  font-size: $font-size-10;
}

.fontSize12 {
  font-size: $font-size-12;
}

.fontSize14 {
  font-size: $font-size-14;
}

.fontSize16 {
  font-size: $font-size-16;
}

.fontSize18 {
  font-size: $font-size-18;
}

.fontSize20 {
  font-size: $font-size-20;
}

.fontSize22 {
  font-size: $font-size-22;
}

.fontSize24 {
  font-size: $font-size-24;
}

.fontSize32 {
  font-size: $font-size-32;
}

/* Font-weight */
.fontWeight500 {
  font-weight: 500;
}

.fontWeight600 {
  font-weight: 600;
}

.fontWeight700 {
  font-weight: 700;
}

.fontWeight800 {
  font-weight: 800;
}

/* Color */

.primaryColor {
  color: $primary-color;
}

.secondaryColor {
  color: $secondary-color;
}

.tertiaryColor {
  color: $tertiary-color;
}

.greenColor {
  color: $green-color;
}

/* Background */
.backPrimary {
  background-color: $primary-color;
}
.backSecondary {
  background-color: $secondary-color;
}
.backTertiary {
  background-color: $tertiary-color;
}

/* Width */
.fullWidthHeight{
  width: 100%;
  height: 100%;
}

.width100 {
  width: 100%;
}

.width80 {
  width: 80%;
}

.width50 {
  width: 50%;
}

.width600 {
  max-width: 600px;
}

/* height */

.height100 {
  height: 100%;
}

.height80 {
  height: 80%;
}

.height60 {
  min-height: 60%;
}

.height50 {
  height: 50%;
}

/* Padding */
.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.p50 {
  padding: 50px;
}

/** Vertical **/
.pV30 {
  padding: 30px 0;
}

/** Bottom **/
.pB8 {
  padding-bottom: 8px;
}

/** Left **/
.pL30 {
  padding-left: 30px;
}


/* Margin */

/** Bottom **/

.mB4 {
  margin-bottom: 4px;
}

.mB8 {
  margin-bottom: 8px;
}

.mB16 {
  margin-bottom: 16px;
}

/** Top **/

.mT4 {
  margin-top: 4px;
}

.mT8 {
  margin-top: 8px;
}

.mT16 {
  margin-top: 16px;
}

/** left **/

.mL4 {
  margin-left: 4px;
}

.mL8 {
  margin-left: 8px;
}

.mL16 {
  margin-left: 16px;
}

/** right **/

.mR4 {
  margin-right: 4px;
}

.mR8 {
  margin-right: 8px;
}

.mR16 {
  margin-right: 16px;
}

.borderBox {
  box-sizing: border-box;
}

/* Flexbox */
@mixin flex($justify, $align, $direction) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

.flexHorizontalCenter {
  @include flex(center, center, row);
}

.flexHorizontal {
  @include flex(flex-start, center, row);
}

.flexHorizontalSpaceBetweenStart {
  @include flex(space-between, flex-start, row);
}

.flexHorizontalSpaceBetweenCenter {
  @include flex(space-between, center, row);
}

.flexHorizontalSpaceEvenlyCenter {
  @include flex(space-evenly, center, row);
}

.flexHorizontalStart {
  @include flex(flex-start, flex-start, row);
}

.flexVertical {
  @include flex(flex-start, center, column);
}

.flexVerticalCenter {
  @include flex(center, center, column);
}

.flexVerticalStart {
  @include flex(flex-start, flex-start, column);
}

.flexVerticalCenterStart {
  @include flex(center, flex-start, column);
}

.flexSpaceBetweenCenter {
  @include flex(space-between, center, row);
}

.flexVerticalBetweenCenter {
  @include flex(space-between, center, column);
}

.flexVerticalEnd {
  @include flex(flex-start, flex-end, column);
}

.flexSpaceBetweenVertical {
  @include flex(space-between, center, column);
}

.flexWrap {
  flex-wrap: wrap;
}

.gap4 {
  gap: 4px;
}

.gap8 {
  gap: 8px;
}

.gap16 {
  gap: 16px;
}

.gap24 {
  gap: 24px;
}

.gap32 {
  gap: 32px;
}

/* Radius */
.radius8 {
  border-radius: 8px;
}

.radius18 {
  border-radius: 18px;
}

/* Position */
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom0 {
  bottom: 0;
}

.bottom35 {
  bottom: 35px;
}

/* Text */

.textAlignCenter{
  text-align: center;
}

/* Overflow */

.overflowX {
  overflow-x: auto;
}

.overflowY {
  overflow-y: auto;
}

.overflow {
  overflow: auto;
}

/* Cursor */

.pointer {
  cursor: pointer;
}