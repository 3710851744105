@import '../style/variablesColor';

.separator {
  background-color: rgba(189, 189, 189, 0.60);
  height: 1px;
}

.line {
  background-color: $color-blue-800;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease, transform 0.3s ease;
  transform-origin: left center;
}

.disableTabs {
  color: rgba(29, 53, 87, 0.50);
}