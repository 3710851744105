@import "../../style/variables";
@import "../style/variablesColor";


.button {

  button {

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: $font-family-urbanist;
      font-weight: 600;
      font-size: $font-size-16;
    }

    .spinner {
      animation: spin 1.5s linear infinite;
      display: flex;
      align-items: center;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg); /* Angle initial de rotation */
      }
      100% {
        transform: rotate(360deg); /* Angle final de rotation */
      }
    }

    @media (max-width: 850px) {
      > div {
        font-size: $font-size-14;
      }
    }
  }

  .success.text, .success {
    color: $color-green-600;
    background-color: transparent;

    &:hover {
      color: $color-green-700;
    }
  }

  .success.contained {
    background-color: $color-green-600;
    color: $color-white;

    &:hover {
      background-color: $color-green-700;
    }
  }

  .success.outlined {
    color: $color-green-600;
    border: 2px solid $color-green-600;
    background-color: transparent;

    &:hover {
      color: $color-green-700;
      border: 2px solid $color-green-600;

    }
  }

  .error.text {
    color: $color-red-500;
    background-color: transparent;

    &:hover {
      color: $color-red-600;
    }
  }

  .error.contained {
    background-color: $color-red-500;
    color: $color-white;

    &:hover {
      background-color: $color-red-600;
    }
  }

  .error.outlined {
    color: $color-red-500;
    border: 2px solid $color-red-600;
    background-color: transparent;

    &:hover {
      color: $color-red-500;
      border: 2px solid $color-red-600;
    }
  }

  .primary.text {
    color: $color-blue-800;
    background-color: transparent;

    &:hover {
      color: $color-blue-900;
    }
  }

  .primary.contained {
    background-color: $color-blue-800;
    color: $color-white;

    &:hover {
      background-color: $color-blue-900;
    }
  }

  .primary.outlined {
    color: $color-blue-800;
    border: 2px solid $color-blue-800;
    background-color: transparent;

    &:hover {
      color: $color-blue-900;
      border: 2px solid $color-blue-900;
    }
  }


  .secondary.text {
    color: $color-blue-100;
    background-color: transparent;

    &:hover {
      color: $color-blue-200;
    }
  }

  .secondary.contained {
    background-color: $color-blue-100;
    color: $color-blue-800;

    &:hover {
      background-color: $color-blue-200;
    }
  }

  .secondary.outlined {
    color: $color-blue-100;
    border: 2px solid $color-blue-100;
    background-color: transparent;

    &:hover {
      color: $color-blue-200;
      border: 2px solid $color-blue-200;

    }
  }

  .disable.text {
    color: $color-gray-400;
    background-color: transparent;
  }

  .disable.outlined {
    color: $color-gray-400;
    border: 2px solid $color-gray-400;
    background-color: transparent;
  }

  .disable.contained {
    background-color: $color-gray-200;
    color: $color-gray-400;
  }


  .contained, .outlined {
    border-radius: 8px;
    padding: 8px 50px;
  }
}

