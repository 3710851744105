/** Variables **/

$font-size-8: 8px;
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-32: 32px;

$font-family-urbanist: 'Urbanist', sans-serif;
$font-family-poppins: 'Poppins', sans-serif;
$font-family-unbounded: 'Unbounded', sans-serif;

$primary-color: #1D3557;
$secondary-color: #001B1F;
$tertiary-color: #F5F5FA;
$green-color: #26C485;
$red:#FF4747